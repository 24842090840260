import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

const deliveryServices = {
  /**
   * 交车单分配/重新分配
   */
  async deliverAllocationPost(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/assign`, params).then(thenResolve)
  },
  /**
   * 交车列表
   */
  async getDeliverList(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/page`, params).then(thenResolve)
  },
  /**
   * 交车列表数量
   */
  async getDeliverCount(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/pageCount`, params).then(thenResolve)

  },
  /**
   * 交车详情
   */
  async deliveryDetail(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/detail`, { params }).then(thenResolve)
  },
  /**
   * 获取交付关注事项
  */
  async deliveryTask(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/delivery-task`, { params }).then(thenResolve)
  },
  /**
   * 执行交车任务
  */
  async deliveryExecution(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/task-execution`, params)
  },
  /**
   * 查询交车任务历史
  */
  async deliveryTaskHistory(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/delivery-task-history`, { params }).then(thenResolve)
  },
  /**
   * 交车跟进
  */
  async deliveryFollow(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/delivery-follow`, params).then(thenResolve)
  },
  /**
   * 交车轨迹
  */
  async deliveryStatusHis(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/status-history`, { params }).then(thenResolve)
  },
  /**
   * 交车临时授权
  */
  async deliveryUserAuth(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/delivery-user-auth`, params).then(thenResolve)
  },
  /**
   * 获取交车排程数量
  */
  async deliveryBookDate(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/book-date`, { params }).then(thenResolve)
  },
  /**
   * 查询当日排程
  */
  async deliveryBookDay(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/book-day`, { params }).then(thenResolve)
  },
  // 任务详情
  deliveryTaskDetail(params) {
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/task-detail`, { params }).then(thenResolve)
  },

  // 开票信息
  billDetail(params) {
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/query-invoice`, { params }).then(thenResolve)
  },

  // 申请撤销金融单
  async cancelFinance(financeId) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/cancel-finance?financeId=${financeId}`, {}).then(thenResolve)
  },

  // 上传发票
  async uploadInvoice(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/upload-invoice`, params)
  },
  // 获取店内的交付能力配置
  async getDeliverConfig(params) {
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/deliveryConfig/info`, { params }).then(thenResolve)
  },
  // 查询预约时间是否受限制
  async isTimeConflict(params) {
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/book-time`, { params }).then(thenResolve)
  },
  // 整备单查询
  async vehicleReady(params) {
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/vehicleMaintain/matchByOrderId`, { params }).then(thenResolve)
  },
  // 确认完成交付
  async deliveryConfirm(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/delivery-confirm?orderId=${params.orderId}`)
  },
  // 确认完成交付
  async uploadTemplateFile(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/order/uploadTemplateFile`, params).then(thenResolve)
  },
  // 获取同批次的合同
  async getBatchOrder(params) {
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/deliveryByContractNo`,{ params }).then(thenResolve)
  },
  // 获取交付提醒列表
  async deliverRemindList(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/deliveryRemind/list`, params).then(thenResolve)
  },
  // 预约到店看车
  async deliverRemindBook(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/deliveryRemind/book-visit`, params).then(thenResolve)
  },
  // 异地交付申请
  async remoteDeliverRequest(params){
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/offsiteDelivery/upset`, params).then(thenResolve)
  },
  // 异地交付申请详情
  async remoteRequestDetails(params) {
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/offsiteDelivery/query`,{ params }).then(thenResolve)
  }, 
  // 异地交付审核
  async remoteDeliverAudit(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/offsiteDelivery/audit`,params).then(thenResolve)
  }, 
  // 异常流程审批统计
  async errorApproveCount(params){
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/deliveryReview/count`,params).then(thenResolve)
  },
  // 异常流程审批列表
  async errorApproveList(params){
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/deliveryReview/list`,params).then(thenResolve)
  },
  // 异常流程提交审批
  async errorApproveSubmit(params){
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/deliveryReview/commit`,params).then(thenResolve)
  },
  // 异常流程审批
  async errorApproveReview(params){
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/deliveryReview/review`,params).then(thenResolve)
  },
  // 保险
  async policyDetail(params){
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/delivery/policy-detail`,{ params }).then(thenResolve)
  },
  // 锁定列表vin查询
  async lockListByVin(vin){
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/vehicleLock/listByVin?vin=${vin}`).then(thenResolve)
  },
  // 锁定列表orderId查询
  async lockListByOrderId(orderId){
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/vehicleLock/listByOrderId?orderId=${orderId}`).then(thenResolve)
  },
  // 异地交付配置
  async getDeliveryConfig(params){
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/offsiteDeliveryConfig/query`,{ params }).then(thenResolve)
  },
  // 异地交付费用申请
  async remoteDeliverCostRequest(params){
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/offsiteDelivery/upset-cost`, params).then(thenResolve)
  },
  // 异地交付审核历史
  async getReviewHistory(orderId){
    return request.get(`${SERVER.ORDER_SERVICE}/api/v1/offsiteDelivery/auditHistory?orderId=${orderId}`,{ }).then(thenResolve)
  },
  async getProtocolLink(code,orderId){
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/delivery/query-protocol-link?orderId=${orderId}&code=${code}`,{}).then(thenResolve)
  }
  
}
export default deliveryServices
