<template>
  <form-select-wrap-cascader
    :columns="list"
    :showPicker="showPicker"
    title="请选择取消原因"
    :loading="loading"
    :empty="empty"
    @confirm="onConfirm"
    @cancel="cancel"
  >
  </form-select-wrap-cascader>
</template>

<script>
import testDriveServices from '@/services/testDrive'
import formSelectWrapCascader from './form-select-wrap-cascader'
export default {
  components: {
    formSelectWrapCascader
  },
  resolve: null,
  reject: null,
  data() {
    return {
      list: [],
      showPicker: false,
      loading: true,
      empty: false
    }
  },
  watch: {
    list: {
      immediate: true,
      handler(newList) {
        if (newList.length === 0) {
          this.empty = true
        } else {
          this.empty = false
        }
      }
    }
  },
  methods: {
    async getList() {
      this.loading = true
      const res = await testDriveServices.cancelReasonList()
      this.loading = false
      this.list = res.filter(({ disable })=> !(typeof disable == 'boolean' && disable) )
    },
    onConfirm(value) {
      this.resolve(value)
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    }
  },
}
</script>

<style>
</style>