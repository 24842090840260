import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'
import { genSignHeaders } from '@/utils/signUtil'

const testDriveServices = {
  /**
   * 试驾记录报告
  */
  async getTestDriveReport(params) {
    return await request.post('/external' + SERVER.THIRD_SYSTEM_SERVICE + '/api/v1/third/external/drive/record/report', params,
      { headers: genSignHeaders({ host: 'scrm-third-system-gateway', method: 'POST', uri: '/external' + SERVER.THIRD_SYSTEM_SERVICE + '/api/v1/third/external/drive/record/report', requestBody: params }) }).then(thenResolve)
  },
  /**
   * 试驾记录报告V2
  */
  async getTestDriveReportV2(params) {
    // return await request.post(`${SERVER.Test_Drive}/client/v1/record/reportV2`, params).then(thenResolve)
    return await request.post(`/external${SERVER.Test_Drive}/client/v1/record/reportV2`, params, {
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/record/reportV2`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 试驾记录报告V3
  */
  async getTestDriveReportV3(params) {
    return await request.post(`/external${SERVER.Test_Drive}/client/v1/record/reportV3`, params, {
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/record/reportV3`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 修改试驾报告
  */
  async updateDriveRecordReport(params) {
    // return await request.post(`${SERVER.Test_Drive}/client/v1/record/updateDriveRecordReport`, params).then(thenResolve)
    return await request.post(`/external${SERVER.Test_Drive}/client/v1/record/updateDriveRecordReport`, params, {
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/record/updateDriveRecordReport`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 修改试驾报告V3
  */
  async updateDriveRecordReportV3(params) {
    return await request.post(`/external${SERVER.Test_Drive}/client/v1/record/updateDriveRecordReportV3`, params, {
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/record/updateDriveRecordReportV3`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 获取临时cos密钥
   */
  async getCosTmpKey(params) {
    // return await request.post(`${SERVER.Test_Drive}/v1/common/cos/tmp_key`, params).then(thenResolve)
    return await request.post(`/external${SERVER.Test_Drive}/v1/common/cos/tmp_key`, params, {
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/v1/common/cos/tmp_key`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 试驾报告V3-生成二维码链接
  */
  async getQrCodeUrl(params) {
    return await request.post('/external' + SERVER.THIRD_SYSTEM_SERVICE + '/api/v1/car-sales/query/introductUrl', params, {  
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.THIRD_SYSTEM_SERVICE}/api/v1/car-sales/query/introductUrl`, requestBody: params })
    }).then(thenResolve)
  },
  /**
   * 获取报告详情
   */
  async reportDetail(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/record/detail`, params).then(thenResolve)
  },
  /**
   * 获取报告详情V3
   */
  async reportDetailV3(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/record/detailV3`, params).then(thenResolve)
  },
  /**
   * 报告推送
   */
  async reportSend(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/record/send`, params).then(thenResolve)
  },
  /**
   * 获取试驾礼品配置
  */
  async giftConfigDetail(params) {
    return await request.get(`${SERVER.Test_Drive}/backend/v1/testDriveGift/configDetail`, { params }).then(thenResolve)
  },
  /**
   * 获取试驾礼品配置 走第三方网关
   */

  async giftConfigDetailByThirid(params) {
    return await request.get(`/external${SERVER.Test_Drive}/backend/v1/testDriveGift/configDetail`, { 
      params,
      headers: genSignHeaders({ method: 'GET', uri: `/external${SERVER.Test_Drive}/backend/v1/testDriveGift/configDetail`, requestBody: params }) },
    ).then(thenResolve)
  },
  /**
   * 获取试驾礼品配置-是否展示领取入口
   */
  async giftConfigEntrance(params) {
    return await request.post(`/external${SERVER.Test_Drive}/client/v1/gift/can/entrance`, params, {
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/gift/can/entrance`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 查询礼品详情
   */
  async getGiftDetail(params) {
    return await request.get(`${SERVER.Test_Drive}/client/v1/gift/detail`, { params }).then(thenResolve)
  },
  /**
   * 查询礼品详情 走第三方网关
   */
  async getGiftDetailByThirid(params) {
    return await request.get(`/external${SERVER.Test_Drive}/client/v1/gift/detail`, { 
      params,
      headers: genSignHeaders({ method: 'GET', uri: `/external${SERVER.Test_Drive}/client/v1/gift/detail?appointId=${params.appointId}`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 查询抽奖礼品列表
   */
  async getGiftList(params) {
    return await request.get(`${SERVER.Test_Drive}/client/v1/gift/getGifts`, { params }).then(thenResolve)
  },
  /**
   * 查询抽奖礼品列表 走第三方网关
   */
  async getGiftListByThirid(params) {
    return await request.get(`/external${SERVER.Test_Drive}/client/v1/gift/getGifts`, {
      params,
      headers: genSignHeaders({ method: 'GET', uri: `/external${SERVER.Test_Drive}/client/v1/gift/getGifts`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 礼品抽奖
   */
  async giftLottery(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/gift/lottery`, params).then(thenResolve)
  },
  /**
   * 礼品抽奖 走第三方网关
   */
  async giftLotteryByThirid(params) {
    return await request.post(`/external${SERVER.Test_Drive}/client/v1/gift/lottery`, params, {
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/gift/lottery`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 线下领取
   */
  async giftOfflineReceive(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/gift/offLine`, params).then(thenResolve)
  },
  /**
   * 线下领取 走第三方网关
   */
  async giftOfflineReceiveByThirid(params) {
    return await request.post(`/external${SERVER.Test_Drive}/client/v1/gift/offLine`, params, {
      headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/gift/offLine`, requestBody: params }) 
    }).then(thenResolve)
  },
  /**
   * 根据预约单id查询试驾礼品领取信息
   */
  async queryReceiveRecord(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/gift/queryReceiveRecord`, params).then(thenResolve)
  },
  /**
   * 领取试驾礼
   */
  async giftReceive(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/gift/receive`, params).then(thenResolve)
  },
  /**
   * 领取试驾礼 走第三方网关
   */
  async giftReceiveByThirid(params, params2) {
    const headersSign = genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/gift/receive`, requestBody: params }) 
    return await request.post(`/external${SERVER.Test_Drive}/client/v1/gift/receive`, params, {
      headers: { ...headersSign, ...params2 }
    })
  },
  /**
   * 查询试驾单礼品领取资格
   */
  async reveiveJudge(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/gift/receiveJudge`, params).then(thenResolve)
  },
  /**
   * 线上领取发消息
   */
  async giftSend({ appointId }) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/gift/send?appointId=${appointId}`).then(thenResolve)
  },
  /**
   * 修改礼品
  */
  async updateGift(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/gift/update`, params).then(thenResolve)
  },
  /**
   * 获取试驾礼品可供选择的领取方式
   */
  async testDriveConfig(params) {
    return await request.post(`${SERVER.Test_Drive}/backend/v1/testDriveConfig/detail`, params).then(thenResolve)
  },
  // 试驾信息签署
  async testDriveInfoSign(params){
    return await request.post(`${SERVER.Test_Drive}/client/v1/appointment/fill/info`, params).then(thenResolve)
  },
  /**
   * 领取试驾礼品获取验证码
  */
  async sendSmsVerify(params, params2) {
    // return await request.post(`/external${SERVER.Test_Drive}/client/v1/gift/send/sms/verify`, params, {
    //   headers: genSignHeaders({ method: 'POST', uri: `/external${SERVER.Test_Drive}/client/v1/gift/send/sms/verify`, requestBody: params }) 
    // }).then(thenResolve)
    return await request.get(`/external${SERVER.Test_Drive}/client/v1/gift/send/sms/verify`, { 
      params,
      headers: genSignHeaders({ method: 'GET', uri: `/external${SERVER.Test_Drive}/client/v1/gift/send/sms/verify?phone=${params.phone}`, requestBody: params })
    })
  },
  /**
   * 根据预约单id查询试驾礼品领取信息
   */
  async cancelReasonList(params = {}) {
    return await request.get(`${SERVER.Test_Drive}/backend/v1/testDrive/cancelReason/list`, { params }).then(thenResolve)
  },
}
export default testDriveServices
